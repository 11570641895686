import React, { FC, ReactNode } from 'react';
import { Header } from './Header';
import '../styles/layouts/Header.scss';
import { Footer } from './Footer';

interface IBasePage {
  children: ReactNode;
}

export const BasePage: FC<IBasePage> = ({ children }) => {
  return (
    <div>
      <Header />
      <div className={'content-page'}>{children}</div>
      <Footer />
    </div>
  );
};
