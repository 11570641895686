import React, { useState, useEffect } from 'react';
import '../styles/layouts/Header.scss';

const MENUS = [
  {
    name: 'Home',
    id: '#home',
  },
  {
    name: 'Services',
    id: '#services',
  },
  {
    name: 'Projects',
    id: '#projects',
  },
  // {
  //   name: 'Follow Us on Instagram',
  //   id: '#follow-instagram',
  // },
  {
    name: 'Testimonials',
    id: '#testimonials',
  },
  {
    name: 'Contact Us',
    id: '#contact-us',
  },
];

// const instagramUrl = 'https://www.instagram.com/wiretokk/';

export const Header = () => {
  const [isShowMenuMobile, setIsShowMenuMobile] = useState<boolean>(false);

  useEffect(() => {
    if (isShowMenuMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowMenuMobile]);

  const handleScrollClick = (id: string) => {
    if (!id) return;
    const divRef: any = document.getElementById(id);
    const divOffset = divRef?.offsetTop;
    let offsetSmooth = divOffset - 150;

    if (window.innerWidth < 992) {
      offsetSmooth = divOffset - 80;
    }

    window.scrollTo({
      top: offsetSmooth,
      behavior: 'smooth',
    });
  };

  return (
    <div className={`${isShowMenuMobile ? 'mobile' : ''} header`} id="header">
      {!isShowMenuMobile && (
        <div className="header__content">
          <div
            className="header__logo"
            onClick={() => {
              handleScrollClick('home');
            }}
          >
            <img src={'/images/logo.svg'} alt="logo" />
          </div>

          <div className="header__menu" onClick={() => setIsShowMenuMobile(true)}>
            <div className="icon-menu"></div>
          </div>

          <div className="list-menu">
            <div className="list-menu">
              {MENUS.map((menu, index) => {
                return (
                  <div
                    className="list-menu__item"
                    key={index}
                    onClick={() => handleScrollClick(menu.id.replace('#', ''))}
                  >
                    {menu.name}
                  </div>
                );
              })}
            </div>
            <div className="list-menu-contact">
              <div className="contact-info">
                <div className="contact-item">
                  <div className="icon-email-header"></div>
                  <div className="contact-item__info">
                    <a href="mailto:info@wiretokk.com">info@wiretokk.com</a>
                  </div>
                </div>
                <div className="contact-item">
                  <div className="icon-phone-header"></div>
                  <div className="contact-item__info">
                    <a href="tel:(613) 884-0005">(613) 884-0005</a>
                  </div>
                </div>
              </div>

              {/*<a*/}
              {/*  href={instagramUrl}*/}
              {/*  target="_blank"*/}
              {/*  title="wiretokkinc"*/}
              {/*  rel="noreferrer"*/}
              {/*>*/}
              {/*  <div className="icon-wiretokk"></div>*/}
              {/*</a>*/}
            </div>
          </div>
        </div>
      )}

      {isShowMenuMobile && (
        <div className="header__mobile">
          <div className="header__icon-close">
            <div className="icon-close-menu" onClick={() => setIsShowMenuMobile(false)}></div>
          </div>

          <div className="header__mobile-menu">
            {MENUS.map((menu, index) => {
              return (
                <div
                  className="header__mobile-menu--item"
                  key={index}
                  title={menu.name}
                  onClick={() => {
                    handleScrollClick(menu.id.replace('#', ''));
                    setIsShowMenuMobile(false);
                  }}
                >
                  {menu.name}
                </div>
              );
            })}

            <div className="list-menu-contact">
              <div className="contact-item">
                <div className="icon-email-header"></div>
                <div className="contact-item__info" title="info@wiretokk.com">
                  <a href="mailto:info@wiretokk.com">info@wiretokk.com</a>
                </div>
              </div>
              <div className="contact-item">
                <div className="icon-phone-header"></div>
                <div className="contact-item__info" title="(613) 884-0005">
                  <a href="tel:(613) 884-0005">(613) 884-0005</a>
                </div>
              </div>

              {/*<a*/}
              {/*  href={instagramUrl}*/}
              {/*  target="_blank"*/}
              {/*  title="wiretokkinc"*/}
              {/*  rel="noreferrer"*/}
              {/*>*/}
              {/*  <div className="icon-wiretokk"></div>*/}
              {/*</a>*/}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
