import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import config, { ITestimonial } from '../../config';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

interface Props {
  data: Array<ITestimonial>;
}

const PartTestimonials = (props: Props) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const getData = () => {
    fetch('config/data.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {});
  };
  useEffect(() => {
    getData();
  }, []);

  const _renderNavigation = () => {
    return (
      <div className="navigation-slide">
        <div className="btn-navigation-project-prev" ref={navigationPrevRef}>
          <img className="" src="./images/arrow-left.svg" alt="arrow-left" />
        </div>
        <div className="my-custom-pagination-project-div" />
        <div className="btn-navigation-project-next" ref={navigationNextRef}>
          <img className="" src="./images/arrow-right.svg" alt="arrow-right" />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="list-testimonial" id="testimonials">
        <h2 className="list-testimonial__title">Testimonials</h2>
        <div className="list-testimonial__content">
          <Swiper
            spaceBetween={10}
            navigation={{
              prevEl: '.btn-navigation-project-prev',
              nextEl: '.btn-navigation-project-next',
            }}
            pagination={{
              el: '.my-custom-pagination-project-div',
              clickable: true,
              renderBullet: (index, className) => {
                return '<span class="' + className + '">' + '</span>';
              },
            }}
            breakpoints={{
              560: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 70,
              },
            }}
            modules={[Pagination, Navigation]}
          >
            <div className="row">
              {props.data.map((testimonial: ITestimonial, index) => {
                return (
                  <SwiperSlide key={index} className="testimonial">
                    <div>
                      <div className="testimonial__content">
                        <div className="testimonial__description">{testimonial.description}</div>
                        <div className="testimonial__name">{testimonial.name}</div>
                        <div className="testimonial__position">{testimonial.position}</div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>

          {_renderNavigation()}
        </div>
      </div>
    </>
  );
};

export default PartTestimonials;
