import React, { useRef } from 'react';
import config, { IService } from '../../config';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

interface Props {
    data: Array<IService>
}
const PartServices = (props: Props) => {
  const dataServices = props.data;

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const _renderNavigation = () => {
    return (
      <div className="navigation-slide service">
        <div className="btn-navigation-service-prev" ref={navigationPrevRef}>
          <img className="" src="./images/arrow-left.svg" alt="arrow-left" />
        </div>
        <div className="my-custom-pagination-service-div" />
        <div className="btn-navigation-service-next" ref={navigationNextRef}>
          <img className="" src="./images/arrow-right.svg" alt="arrow-right" />
        </div>
      </div>
    );
  };

  const _renderServiceMobile = () => {
    return (
      <div>
        <Swiper
          spaceBetween={10}
          navigation={{
            prevEl: '.btn-navigation-service-prev',
            nextEl: '.btn-navigation-service-next',
          }}
          pagination={{
            el: '.my-custom-pagination-service-div',
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + '">' + '</span>';
            },
          }}
          breakpoints={{
            600: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
          }}
          modules={[Pagination, Navigation]}
        >
          <div className="row">
            {dataServices.map((service: IService, index: number) => {
              return (
                <SwiperSlide key={index} className="service">
                  <div className="service">
                    <div className="service__image">
                      <img src={service.image} alt={service.name} />
                    </div>
                    <h3 className="service__name">{service.name}</h3>
                    <div className="service__desc">{service.description}</div>
                  </div>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>
        {_renderNavigation()}
      </div>
    );
  };

  return (
    <div className="services" id="services">
      <h2 className="services__title">Services</h2>
      <div className="services__desktop">
        <div className="row">
          {dataServices.map((service: IService, index: number) => {
            return (
              <div key={index} className="col-6 col-sm-6 col-md-6 col-lg-4">
                <div className="service">
                  <div className="service__image">
                    <img src={service.image} alt={service.name} />
                  </div>
                  <h3 className="service__name">{service.name}</h3>
                  <div className="service__desc">{service.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="services__mobile">{_renderServiceMobile()}</div>
    </div>
  );
};

export default PartServices;
