import Modal from 'react-modal';
import React, { FC } from 'react';

interface IModalSuccess {
  open: boolean;
  onClose: () => void;
}

const ModalSuccess: FC<IModalSuccess> = ({ open, onClose }) => {
  return (
    <Modal
      isOpen={open}
      ariaHideApp={false}
      onRequestClose={onClose}
      portalClassName="modal-success"
      style={{
        overlay: {
          background: 'rgba(0, 0, 0, 0.80)',
          zIndex: 9999,
        },
        content: {
          width: '648px',
          height: 'auto',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          padding: '20px 20px 16px 20px',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <div>
        <div className="modal-success__icon-close">
          <div className="icon-close-modal" onClick={onClose}></div>
        </div>

        <div className="modal-success__content">
          <img src={'/images/image-logo.png'} alt="logo" />

          <div className="modal-success__description">
            Thank you for your request, a member of our team will reply within 48 hours.
          </div>

          <div className="modal-success__btn" onClick={onClose}>
            Done
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSuccess;
