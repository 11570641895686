import React, { FC, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import config, { IProject } from '../../config';
import ModalProject from '../../modals/ModalProject';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

interface IProjectItem {
  project: IProject;
}

const ProjectItem: FC<IProjectItem> = ({ project }) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (isShowModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowModal]);

  return (
    <div>
      <div className="project__content">
        <div className="project__image">
          <img src={project.image} alt={project.name} />
        </div>
        <div className="project__name">
          {project.name}
          <div className="project__view-more" onClick={() => setIsShowModal(true)}>
            View More
          </div>
        </div>
      </div>

      <ModalProject open={isShowModal} onClose={() => setIsShowModal(false)} project={project} />
    </div>
  );
};

interface Props {
  data: Array<IProject>
}
const PartProjects = (props:Props) => {
  const dataProjects = props.data;
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const _renderNavigation = () => {
    return (
      <div className="navigation-slide navigation-project">
        <div className="btn-navigation-prev" ref={navigationPrevRef}>
          <img className="" src="./images/arrow-left.svg" alt="arrow-left" />
        </div>
        <div className="my-custom-pagination-div" />
        <div className="btn-navigation-next" ref={navigationNextRef}>
          <img className="" src="./images/arrow-right.svg" alt="arrow-right" />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="list-project">
        <h2 className="list-project__title" id="projects">
          Projects
        </h2>

        <div className="list-project__content">
          <Swiper
            id={'project'}
            spaceBetween={10}
            navigation={{
              prevEl: '.btn-navigation-prev',
              nextEl: '.btn-navigation-next',
            }}
            pagination={{
              el: '.my-custom-pagination-div',
              clickable: true,
              renderBullet: (index, className) => {
                return '<span class="' + className + '">' + '</span>';
              },
            }}
            breakpoints={{
              375: {
                slidesPerView: 1,
                spaceBetween: 24,
              },
              480: {
                slidesPerView: 1.5,
                spaceBetween: 24,
              },
              600: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 24,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 24,
              },
              1250: {
                slidesPerView: 4,
                spaceBetween: 32,
              },
            }}
            modules={[Pagination, Navigation]}
          >
            <div className="row">
              {dataProjects.map((project: IProject, index: number) => {
                return (
                  <SwiperSlide key={index} className="project">
                    <ProjectItem project={project} />
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
          {_renderNavigation()}
        </div>
      </div>
    </>
  );
};

export default PartProjects;
