import Modal from 'react-modal';
import React, { FC, useRef, useState } from 'react';
import { IProject } from '../config';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, FreeMode, Navigation, Pagination, Thumbs } from 'swiper';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

interface IModalSuccess {
  open: boolean;
  onClose: () => void;
  project: IProject;
}

export const Gallery = ({ project }: { project: IProject }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const _renderNavigation = () => {
    return (
      <div className="navigation-slide gallery">
        <div className="btn-navigation-gallery-prev" ref={navigationPrevRef}>
          <img className="" src="./images/arrow-left.svg" alt="arrow-left" />
        </div>
        <div className="btn-navigation-gallery-next" ref={navigationNextRef}>
          <img className="" src="./images/arrow-right.svg" alt="arrow-right" />
        </div>
      </div>
    );
  };

  return (
    <div className="modal-project__gallery">
      <div className="modal-project__gallery-main">
        <Swiper
          id={'swiper-gallery'}
          loop
          navigation={{
            prevEl: '.btn-navigation-gallery-prev',
            nextEl: '.btn-navigation-gallery-next',
          }}
          spaceBetween={0}
          effect={'fade'}
          slideActiveClass={'swiper-slide-main-active'}
          pagination={false}
          thumbs={{
            swiper: thumbsSwiper && !thumbsSwiper?.destroyed ? thumbsSwiper : null,
          }}
          modules={[EffectFade, FreeMode, Navigation, Pagination, Thumbs]}
        >
          {project.gallery.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="thumb-main">
                <img src={item} alt={`thumb-${index + 1}`} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {_renderNavigation()}
      </div>
      <div className="swiper-thumb-box">
        <Swiper
          onSwiper={(swiper) => setThumbsSwiper(swiper)}
          spaceBetween={24}
          breakpoints={{
            600: {
              slidesPerView: 4.5,
              spaceBetween: 16,
            },
            992: {
              slidesPerView: 5.5,
              spaceBetween: 24,
            },
          }}
          freeMode
          watchSlidesProgress
          modules={[FreeMode, Navigation, Thumbs]}
          className="swiper-navigation-thumb"
        >
          {project.gallery.map((item, index) => (
            <SwiperSlide key={index + 'swiper-thumb'}>
              <div className="navigation-thumb-item">
                <img src={item} alt={`thumb-${index + 1}`} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

const ModalProject: FC<IModalSuccess> = ({ open, onClose, project }) => {
  return (
    <Modal
      isOpen={open}
      ariaHideApp={false}
      onRequestClose={onClose}
      portalClassName="modal-project"
      style={{
        overlay: {
          background: 'rgba(0, 0, 0, 0.80)',
          zIndex: 9999,
        },
        content: {
          width: `${
            window.innerHeight * 0.9 * 1.3 > 1342 ? 1342 : window.innerHeight * 0.9 * 1.3
          }px`,
          maxHeight: '90%',
          maxWidth: '85%',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          padding: '20px 32px 36px 32px',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <div className="modal-project__content">
        <div className="modal-project__icon-close">
          <div className="icon-close-modal" onClick={onClose}></div>
        </div>

        <div className="modal-project__title">{project.name}</div>
        <Gallery project={project} />
      </div>
    </Modal>
  );
};

export default ModalProject;
