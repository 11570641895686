import React, { useState, useRef } from 'react';
import { Scrollbar } from 'smooth-scrollbar-react';

const PartBanner = () => {
  // const [isPlay, setPlay] = useState<boolean>(false);
  // const videoRef = useRef<any>(null);

  const handleScrollClick = (id: string) => {
    const divRef: any = document.getElementById(id);
    const divOffset = divRef?.offsetTop;
    let offsetSmooth = divOffset - 150;

    if (window.innerWidth < 768) {
      offsetSmooth = divOffset - 85;
    }

    window.scrollTo({
      top: offsetSmooth,
      behavior: 'smooth',
    });
  };

  // const handlePlayVideo = () => {
  //   if (videoRef) {
  //     videoRef?.current?.play();
  //   }
  //   setPlay(true);
  // };
  //
  // const handlePauseVideo = () => {
  //   if (videoRef) {
  //     videoRef?.current?.pause();
  //   }
  //   setPlay(false);
  // };

  // @ts-ignore
  return (
    <div className="banner" id="introduction">
      <div className="banner__video">
        {/*<video*/}
        {/*  ref={videoRef}*/}
        {/*  src={'/video/video-banner.mov#t=0.001'}*/}
        {/*  muted*/}
        {/*  loop*/}
        {/*  playsInline*/}
        {/*  preload={'auto'}*/}
        {/*/>*/}
        {/*{!isPlay && }*/}
        <img src={'/images/banner.jpg'} alt="banner" />

        <div className="banner__layout">
          {/*{!isPlay ? (*/}
            <div className="banner__text">
              <h2 className="banner__text-play">YOUR TECHNOLOGY PARTNER</h2>

              {/*<div className="banner__icon-play" >*/}
              {/*  <div className="icon-play" title="play"></div>*/}
              {/*</div>*/}
            </div>
          {/*) : (*/}
          {/*  <div className="banner__icon-pause" onClick={handlePauseVideo}>*/}
          {/*    <div className="icon-pause" title="pause"></div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>

      <div className="banner__detail">
        <div className="banner__detail-content">
          <div className="row">
            <div className="col-12 col-lg-4 banner__title">
              <h1 className="banner__title">Our Mission</h1>
              <div
                className="banner__btn desktop"
                title="Start your project today"
                onClick={() => handleScrollClick('contact-us')}
              >
                Start your project today
              </div>
            </div>

            <div className="col-12 col-lg-8 banner__description">
              <Scrollbar
                alwaysShowTracks
                plugins={{
                  overscroll: {
                    effect: 'glow',
                  } as any,
                }}
              >
                <div className="banner__description--content">
                  At our core, we are driven to surpass the status quo and set new benchmarks. Our team of highly skilled and knowledgeable installers is committed to leaving nothing to chance. Through continuous training and education, we deliver turnkey systems with remarkable efficiency and effectiveness. <br/>
                  In today's ever-evolving landscape, we acknowledge that technology and low-voltage solutions play a pivotal role in every aspect of our lives. Whether it's convenience or staying connected, we comprehend the profound importance and impact it has on individuals and businesses. Your project is in capable hands as we strive to provide cutting-edge solutions that meet your needs and exceed your expectations.
                </div>
              </Scrollbar>
            </div>

            <div
              className="banner__btn mobile"
              title="Start your project today"
              onClick={() => handleScrollClick('form')}
            >
              Start your project today
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartBanner;
