import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import config from '../../config';
import { toast } from 'react-toastify';
import ModalSuccess from '../../modals/ModalSuccess';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const PartContactUs = () => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const access_key = config.auth.access_key;

  useEffect(() => {
    if (isShowModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowModal]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      name: '',
      phone: '',
      subject: '',
      message: '',
      'h-captcha-response': '',
    },
  });

  const onHCaptchaChange = (token: string) => {
    setValue('h-captcha-response', token);
  };

  const onSubmit = async (data: any) => {
    const json = JSON.stringify({ ...data, access_key: access_key });

    await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: json,
    })
      .then(async (response) => {
        let json = await response.json();
        if (json.success) {
          setIsShowModal(true);
          reset();
        } else {
          toast.error(json?.message || 'Something Went Wrong!');
        }
      })
      .catch((error) => {
        toast.error(error?.message || 'Something Went Wrong!');
      });
  };

  return (
    <div className="contact-us" id="contact-us">
      <h2 className="contact-us__title">Contact Us</h2>

      <div className="contact-us__content">
        <div className="row">
          <div className="col-12 col-lg-7 contact-us__left">
            <div className="contact-us__info">
              <div className="contact-us__info--item">
                <div className="icon-location"></div>
                <div className="contact-us__info--detail">
                  <div className="contact-us__type">Service Area</div>
                  <div className="contact-us__value">
                    Ottawa-Gatineau <br /> Metropolitan Area
                  </div>
                </div>
              </div>
              <div className="contact-us__info--item">
                <div className="icon-email"></div>
                <div className="contact-us__info--detail">
                  <div className="contact-us__type">Email Us</div>
                  <div className="contact-us__value">
                    <a href="mailto:info@wiretokk.com">Info@wiretokk.com</a>
                  </div>
                </div>
              </div>
              <div className="contact-us__info--item">
                <div className="icon-phone"></div>
                <div className="contact-us__info--detail">
                  <div className="contact-us__type">Call Us</div>
                  <div className="contact-us__value">
                    <a href="tel:(613) 884-0005">(613) 884-0005</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-us__box-map">
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1gJiMeq93CXjsg_Tk2L8sjpau_z_d-kc&ehbc=2E312F&noprof=1"
                width="100%"
                height="100%"
                title="map"
              />
            </div>
          </div>

          <div className="col-12 col-lg-5 contact-us__right">
            <form onSubmit={handleSubmit(onSubmit)} className="form" id="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className={'form__field'}>
                    <input
                      type="text"
                      autoComplete="name"
                      required
                      {...register('name', { required: true })}
                      placeholder="Your Name"
                      name="name"
                    />
                    {errors.name && <div className="error">Name is required.</div>}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className={'form__field'}>
                    <input
                      autoComplete="phone"
                      placeholder="Phone"
                      type="number"
                      required
                      {...register('phone', {
                        required: 'Phone is required.',
                      })}
                    />
                    <div className="error">{errors.phone?.message}</div>
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <div className="row box-subject-email">
                    <div className="col-12 col-md-6">
                      <div className={'form__field'}>
                        <input
                          autoComplete="subject"
                          placeholder="Subject"
                          required
                          {...register('subject', {
                            required: 'Subject is required.',
                          })}
                        />
                        <div className="error">{errors.subject?.message}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className={'form__field'}>
                        <input
                          autoComplete="email"
                          placeholder="Email"
                          type="email"
                          required
                          {...register('email', {
                            required: 'Email is required.',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: 'Invalid email address',
                            },
                          })}
                        />
                        <div className="error">{errors.email?.message}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <div className={'form__field'}>
                    <textarea
                      required
                      autoComplete="message"
                      {...register('message', { required: true })}
                      placeholder="How Can We Assist?"
                    />
                    {errors.message && <div className="error">Message is required.</div>}
                  </div>

                  <HCaptcha sitekey={access_key} onVerify={onHCaptchaChange} />

                  <button type="submit" className="form__btn-submit desktop" title="Submit request">
                    Submit request
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ModalSuccess open={isShowModal} onClose={() => setIsShowModal(false)} />
    </div>
  );
};

export default PartContactUs;
