import React, { useEffect, useRef, useState } from 'react';
import '../../styles/pages/HomePage.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import config from '../../config';

interface IPost {
  caption: string;
  id: string;
  media_url: string;
  permalink: string;
  thumbnail_url: string;
  media_type: string;
}

const ACCESS_TOKEN = config.auth.access_token_instagram;

export const PartPostInstagram = () => {
  const [data, setData] = useState<IPost[]>([]);
  const navigationPostPrevRef = useRef(null);
  const navigationPostNextRef = useRef(null);

  const getData = async () => {
    return await(
      await fetch(
        `https://graph.instagram.com/me/media?fields=id,permalink,caption,media_url,media_type,thumbnail_url&limit=12&access_token=${ACCESS_TOKEN}`
      )
    ).json();
  };

  useEffect(() => {
    getData().then((data) => {
      setData(data?.data || []);
    });
  }, []);

  const _renderNavigation = () => {
    return (
      <div className="navigation-slide" key={'instagram'}>
        <div className="btn-navigation-instagram-prev" ref={navigationPostPrevRef}>
          <img className="" src="./images/arrow-left.svg" alt="arrow-left" />
        </div>
        <div className="my-custom-pagination-instagram-div" />
        <div className="btn-navigation-instagram-next" ref={navigationPostNextRef}>
          <img className="" src="./images/arrow-right.svg" alt="arrow-right" />
        </div>
      </div>
    );
  };

  return (
    <div className="instagram" id="follow-instagram">
      <h2 className="instagram__title">Follow Us on Instagram</h2>

      <div className="instagram__content">
        <Swiper
          spaceBetween={10}
          slidesPerGroup={4}
          navigation={{
            prevEl: '.btn-navigation-instagram-prev',
            nextEl: '.btn-navigation-instagram-next',
          }}
          pagination={{
            el: '.my-custom-pagination-instagram-div',
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + '">' + '</span>';
            },
          }}
          breakpoints={{
            375: {
              slidesPerView: 4,
              spaceBetween: 2,
            },
            560: {
              slidesPerView: 4,
              spaceBetween: 2,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          modules={[Pagination, Navigation]}
        >
          <div className="row">
            {data.map((post: IPost, index: number) => {
              return (
                <SwiperSlide key={index} className="instagram__post">
                  <a href={post.permalink} target="_blank" title={post.caption}>
                    <div className="instagram__image">
                      <img
                        src={post.media_type === 'VIDEO' ? post.thumbnail_url : post.media_url}
                        title={post.caption}
                      />
                    </div>
                  </a>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>

        {_renderNavigation()}
      </div>
    </div>
  );
};
