import React from 'react';
import '../../styles/pages/HomePage.scss';

const PARTNERS = [
  {
    image: 'partner1.png',
  },
  {
    image: 'partner2.png',
  },
  {
    image: 'partner3.png',
  },
  {
    image: 'partner4.png',
  },
  {
    image: 'partner5.png',
  },
  {
    image: 'partner6.png',
  },
];

export const PartPartner = () => {
  return (
    <div className="partner">
      {PARTNERS.map((item, index) => {
        return (
          <div key={index}>
            <img src={`/images/partner/${item.image}`} />
          </div>
        );
      })}
    </div>
  );
};
