import React, { useState, useEffect } from 'react';
import '../styles/layouts/Header.scss';
import ModalPolicy from '../modals/ModalPolicy';

export const Footer = () => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (isShowModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowModal]);

  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__text">© WIRETOKK inc.</div>
        <div className="footer__policy" onClick={() => setIsShowModal(true)}>
          Privacy Policy
        </div>
      </div>

     <ModalPolicy open={isShowModal} onClose={() => setIsShowModal(false)}/>
    </div>
  );
};
