import Modal from 'react-modal';
import React, { FC } from 'react';
import { Scrollbar } from 'smooth-scrollbar-react';

interface IModalPolicy {
  open: boolean;
  onClose: () => void;
}

const ModalPolicy: FC<IModalPolicy> = ({ open, onClose }) => {
  return (
    <Modal
      isOpen={open}
      ariaHideApp={false}
      onRequestClose={onClose}
      portalClassName="modal-policy"
      style={{
        overlay: {
          background: 'rgba(0, 0, 0, 0.80)',
          zIndex: 9999,
        },
        content: {
          width: '80%',
          height: '90%',
          maxHeight: '975px',
          maxWidth: '1342px',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          padding: '20px 20px 16px 20px',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <div className="modal-policy__content">
        <div className="modal-policy__icon-close">
          <div className="icon-close-modal" onClick={onClose}></div>
        </div>

        <div className="modal-policy__title">Privacy Policy</div>

        <div className="modal-policy__description">
          <Scrollbar
            alwaysShowTracks
            plugins={{
              overscroll: {
                effect: 'glow',
              } as any,
            }}
          >
            <div className="modal-policy__description--content">
              This Privacy Policy describes how WIRETOKK inc (the "Site", "we", "us", or "our")
              collects, uses, and discloses your personal information when you visit, use our
              services from WIRETOKK.com (the "Site") or otherwise communicate with us
              (collectively, the "Services"). For purposes of this Privacy Policy, "you" and "your"
              means you as the user of the Services, whether you are a customer, website visitor, or
              another individual whose information we have collected pursuant to this Privacy
              Policy.
              <br />
              <br />
              Please read this Privacy Policy carefully. By using and accessing any of the Services,
              you agree to the collection, use, and disclosure of your information as described in
              this Privacy Policy. If you do not agree to this Privacy Policy, please do not use or
              access any of the Services.
              <br />
              <br />
              Changes to This Privacy Policy
              <br />
              <br />
              We may update this Privacy Policy from time to time, including to reflect changes to
              our practices or for other operational, legal, or regulatory reasons. We will post the
              revised Privacy Policy on the Site, update the "Last updated" date and take any other
              steps required by applicable law.
              <br />
              <br />
              How We Collect and Use Your Personal Information
              <br />
              <br />
              To provide the Services, we collect and have collected over the past 12 months
              personal information about you from a variety of sources, as set out below. The
              information that we collect and use varies depending on how you interact with us.
              <br />
              <br />
              In addition to the specific uses set out below, we may use information we collect
              about you to communicate with you, provide the Services, comply with any applicable
              legal obligations, enforce any applicable terms of service, and to protect or defend
              the Services, our rights, and the rights of our users or others.
              <br />
              <br />
              What Personal Information We Collect
              <br />
              <br />
              The types of personal information we obtain about you depends on how you interact with
              our Site and use our Services. When we use the term "personal information", we are
              referring to information that identifies, relates to, describes or can be associated
              with you. The following sections describe the categories and specific types of
              personal information we collect.
              <br />
              <br />
              Information We Collect Directly from You
              <br />
              <br />
              Information that you directly submit to us through our Services may include:
              <br />
              <br />
              - Basic contact details including your name, address, phone number, email.
              <br />
              - Customer support information including the information you choose to include in
              communications with us, for example, when sending a message through the Services.
              <br />
              <br />
              Some features of the Services may require you to directly provide us with certain
              information about yourself. You may elect not to provide this information, but doing
              so may prevent you from using or accessing these features.
              <br />
              <br />
              How We Use Your Personal Information
              <br />
              <br />
              - Providing Products and Services. We use your personal information to provide you
              with the Services in order to perform our contract with you, including to process your
              payments, fulfill your service requests, and to send notifications to related our
              services.
              <br />
              - Communicating with you. We use your personal information to provide you with
              customer support and improve our Services. This is in our legitimate interests in
              order to be responsive to you, to provide effective services to you, and to maintain
              our business relationship with you.
              <br />
              <br />
              How We Disclose Personal Information
              <br />
              <br />
              In certain circumstances, we may disclose your personal information to third parties
              for legitimate purposes subject to this Privacy Policy. Such circumstances may
              include:
              <br />
              <br />
              - With vendors or other third parties who perform services on our behalf (e.g., IT
              management, payment processing, customer support and cloud storage).
              <br />
              - With our affiliates or otherwise within our corporate group, in our legitimate
              interests to run a successful business.
              <br />
              - In connection with a business transaction such as a merger or bankruptcy, to comply
              with any applicable legal obligations (including to respond to subpoenas, search
              warrants and similar requests), to enforce any applicable terms of service, and to
              protect or defend the Services, our rights, and the rights of our users or others.
              <br />
              <br />
              We have, in the past 12 months disclosed the following categories of personal
              information and sensitive personal information (denoted by *) about users for the
              purposes set out above in "How we Collect and Use your Personal Information" and "How
              we Disclose Personal Information":
              <br />
              <br />
              Category:
              <br />
              <br />
              - Identifiers such as basic contact details
              <br />
              <br />
              Categories of Recipients:
              <br />
              <br />
              - Vendors and third parties who perform services on our behalf
              <br />
              - Business and marketing partners
              <br />
              - Affiliates
              <br />
              <br />
              We do not use or disclose sensitive personal information for the purposes of inferring
              characteristics about you.
              <br />
              <br />
              Category of Personal Information
              <br />
              <br />
              - Identifiers such as basic contact details
              <br />
              - Commercial information such as records of products or services purchased
              <br />
              <br />
              Categories of Recipients
              <br />
              <br />
              - Business and marketing partners
              <br />
              <br />
              Third Party Websites and Links
              <br />
              <br />
              Our Site may provide links to websites or other online platforms operated by third
              parties. If you follow links to sites not affiliated or controlled by us, you should
              review their privacy and security policies and other terms and conditions. We do not
              guarantee and are not responsible for the privacy or security of such sites, including
              the accuracy, completeness, or reliability of information found on these sites.
              Information you provide on public or semi-public venues, including information you
              share on third-party social networking platforms may also be viewable by other users
              of the Services and/or users of those third-party platforms without limitation as to
              its use by us or by a third party. Our inclusion of such links does not, by itself,
              imply any endorsement of the content on such platforms or of their owners or
              operators, except as disclosed on the Services.
              <br />
              <br />
              Security and Retention of Your Information
              <br />
              <br />
              Please be aware that no security measures are perfect or impenetrable, and we cannot
              guarantee "perfect security." In addition, any information you send to us may not be
              secure while in transit. We recommend that you do not use unsecure channels to
              communicate sensitive or confidential information to us.
              <br />
              <br />
              How long we retain your personal information depends on different factors, such as
              whether we need the information to maintain your account, to provide the Services,
              comply with legal obligations, resolve disputes or enforce other applicable contracts
              and policies.
              <br />
              <br />
              Your Rights and Choices
              <br />
              <br />
              Depending on where you live, you may have some or all of the rights listed below in
              relation to your personal information. However, these rights are not absolute, may
              apply only in certain circumstances and, in certain cases, we may decline your request
              as permitted by law.
              <br />
              <br />
              - Right to Access / Know. You may have a right to request access to personal
              information that we hold about you, including details relating to the ways in which we
              use and share your information. <br />
              - Right to Delete. You may have a right to request that we delete personal information
              we maintain about you. <br />
              - Right to Correct. You may have a right to request that we correct inaccurate
              personal information we maintain about you. <br />
              - Right of Portability. You may have a right to receive a copy of the personal
              information we hold about you and to request that we transfer it to a third party, in
              certain circumstances and with certain exceptions. <br />
              - Right to Limit and/or Opt out of Use and Disclosure of Sensitive Personal
              Information. You may have a right to direct us to limit our use and/or disclosure of
              sensitive personal information to only what is necessary to perform the Services or
              provide the goods reasonably expected by an average individual. <br />
              - Restriction of Processing: You may have the right to ask us to stop or restrict our
              processing of personal information. <br />
              - Withdrawal of Consent: Where we rely on consent to process your personal
              information, you may have the right to withdraw this consent. <br />
              - Appeal: You may have a right to appeal our decision if we decline to process your
              request. You can do so by replying directly to our denial. <br />
              - Managing Communication Preferences: We may send you promotional emails, and you may
              opt out of receiving these at any time by using the unsubscribe option displayed in
              our emails to you. If you opt out, we may still send you non-promotional emails, such
              as those about your account or orders that you have made.
              <br />
              <br />
              You may exercise any of these rights where indicated on our Site or by contacting us
              using the contact details provided below.
              <br />
              <br />
              We will not discriminate against you for exercising any of these rights. We may need
              to collect information from you to verify your identity, such as your email address or
              account information, before providing a substantive response to the request. In
              accordance with applicable laws, You may designate an authorized agent to make
              requests on your behalf to exercise your rights. Before accepting such a request from
              an agent, we will require that the agent provide proof you have authorized them to act
              on your behalf, and we may need you to verify your identity directly with us. We will
              respond to your request in a timely manner as required under applicable law.
              <br />
              <br />
              Complaints
              <br />
              <br />
              If you have complaints about how we process your personal information, please contact
              us using the contact details provided below. If you are not satisfied with our
              response to your complaint, depending on where you live you may have the right to
              appeal our decision by contacting us using the contact details set out below, or lodge
              your complaint with your local data protection authority.
              <br />
              <br />
              Contact
              <br />
              <br />
              Should you have any questions about our privacy practices or this Privacy Policy, or
              if you would like to exercise any of the rights available to you, please email us at
              <a href="mailto:info@wiretokk.com">info@wiretokk.com</a> .
            </div>
          </Scrollbar>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPolicy;
