import prod from './prod.json';
import dev from './dev.json';

const env = process.env.REACT_APP_ENV || 'prod';
const configs: any = {
  dev,
  prod,
};

const config: Config = configs[env];

export interface Config {
  auth: {
    access_key: string;
    access_token_instagram: string;
  };
}

export interface IService {
  name: string;
  image: string;
  description: string;
  link: string;
}

export interface ITestimonial {
  name: string;
  description: string;
  position: string;
}

export interface IProject {
  name: string;
  image: string;
  gallery: string[];
}

export default { ...config };
