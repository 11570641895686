import React, { useEffect, useState } from 'react';
import { BasePage } from '../../layouts';
import '../../styles/pages/HomePage.scss';
import PartBanner from './PartBanner';
import PartServices from './PartServices';
import PartTestimonials from './PartTestimonials';
import PartContactUs from './PartContactUs';
import PartProjects from './PartProjects';
import { PartPartner } from './PartPartner';
import { PartPostInstagram } from './PartPostInstagram';

export const HomePage = () => {
  const [data, setData] = useState(null as any);
  const getData = async () => {
    return await (await fetch('config/data.json')).json();
  };

  useEffect(() => {
    getData().then((data) => {
      setData(data.data);
    });
  }, []);

  if (data) {
    return (
      <BasePage>
        <div>
          <PartBanner />
          <div className="content">
            <PartServices data={data.services} />
            <PartProjects data={data.projects} />
            <PartPostInstagram />
            <PartTestimonials data={data.testimonials} />
            <PartContactUs />
            <PartPartner />
          </div>
        </div>
      </BasePage>
    );
  } else {
    return <div> Loading... </div>;
  }
};
